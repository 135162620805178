import React from 'react';
import styled from 'react-emotion';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import Overdrive from 'react-overdrive';
import Img from 'gatsby-image';

import arrow from '../images/left-chevron.svg';

//    background: url("${props => props.theme.bgPattern}") #000;
const Wrapper = styled('div')`
    display: flex;
    position: relative;
`;

const Content = styled('div')`
  margin: 0 auto;
  width: 100%;
  max-width: ${props => props.theme.maxWidths.general};
  padding: 2rem 1.0875rem 16rem 1.0875rem;
  color: ${props => props.theme.colors.secondary};
`;

const Back = styled(Link)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  img[data-info='back'] {
    width: 25px;
    height: 25px;
    margin: 0 1rem 0 0;
  }
`;

const Avatar = styled('div')`
  height: 40px;
  width: 40px;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  -ms-interpolation-mode: nearest-neighbor;

  img {
    border-radius: 50%;
  }
`;

const Name = styled('h4')`
  margin: 0 0 0 1rem;
  color: ${props => props.theme.colors.color};
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 1);
`;

const Title = styled('h1')`
  margin: 0 0 0 1rem;
`;

const Details = styled('div')`
  width: 100%;
  margin-top: 6rem;
  text-align: center;

  h1 {
    color: ${props => props.theme.colors.color};
    text-shadow: 3px 3px 3px rgba(0, 0, 0, 1);
  }

  h2 {
    color: ${props => props.theme.colors.color};
    text-shadow: 3px 3px 3px rgba(0, 0, 0, 1);
  }

  h3 {
    color: ${props => props.theme.colors.color};
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 1);
  }

  h4 {
    color: ${props => props.theme.colors.color};
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 1);
  }

  span {
    &:not(:last-child) {
      margin-right: 0.25rem;
      &:after {
        content: ',';
      }
    }
  }
`;

const Text = styled('div')`
  max-width: 750px;
  margin: 4rem auto 2rem auto;
  color: white;
`;

const ProjectHeader = ({ avatar, name, title, date, areas, text, slug }) => (
  <Wrapper>
    <Content>
      <Back to="/">
        <img src={arrow} data-info="back" alt="test" />
        <Overdrive id="avatar-to-back">
          <Avatar>
            <Img fluid={avatar} />
          </Avatar>
        </Overdrive>
        <Overdrive id="name-to-back">
          <Name>{name}</Name>
        </Overdrive>
      </Back>
      <Details>
        <Overdrive id={`${slug}-title`}>
          <Title>{title}</Title>
        </Overdrive>
        {(date != undefined ? (<h2>{date.substring(date.lastIndexOf(".")+1)}</h2>) : null)}
        <div>
          {(areas != undefined ? areas : []).map(area => (
            <span key={area}>{area}</span>
          ))}
        </div>
        {text && <Text dangerouslySetInnerHTML={{ __html: text }} />}
      </Details>
    </Content>
  </Wrapper>
);

export default ProjectHeader;

ProjectHeader.propTypes = {
  avatar: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  date: PropTypes.string,
  areas: PropTypes.array,
  text: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
};
