import React from 'react';
import styled from 'react-emotion';
import PropTypes from 'prop-types';
import Overdrive from 'react-overdrive';
import Img from 'gatsby-image';
import { Link } from 'gatsby';

//     background: url("${props => props.theme.bgPattern}") #000;
const Wrapper = styled('div')`
    display: flex;
    position: relative;
`;

const Content = styled('div')`
  margin: 0 auto;
  max-width: ${props => props.theme.maxWidths.general};
  padding: 3rem 1.0875rem 10rem 1.0875rem;
  color: ${props => props.theme.colors.secondary};
  text-align: center;
`;

const Avatar = styled('div')`
  height: 125px;
  width: 125px;
  margin: 0 auto;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  -ms-interpolation-mode: nearest-neighbor;

  img {
    border-radius: 50%;
  }
`;

const Name = styled('h1')`
  margin: 1rem 0 0.25rem 0;
  color: ${props => props.theme.colors.color};
  text-shadow: 3px 3px 3px rgba(0, 0, 0, 1);
`;

const Info = styled('div')`
  font-size: 1.0rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${props => props.theme.colors.secondary};

  a {
    color: ${props => props.theme.colors.secondary};
    margin: 0 0.3rem;
  }
  a:hover {
    color: ${props => props.theme.colors.color};
  }
`;

const SocialMedia = styled('div')`
  margin-top: 2rem;

  a {
    margin: 0 0.3rem;
  }
`;

const Header = ({ avatar, name, infos, socialMedia }) => (
  <Wrapper>
    <Content>
      <Overdrive id="avatar-to-back">
        <Avatar>
          <Img fluid={avatar} />
        </Avatar>
      </Overdrive>
      <Overdrive id="name-to-back">
        <Name>{name}</Name>
      </Overdrive>
      <Info>
        {infos.map(info => (
          <Link to={info.node.fields.slug} key={info.node.fields.slug}>
            {info.node.frontmatter.link}
          </Link>
        ))}
      </Info>
      <SocialMedia>
        {socialMedia.map(social => (
          <a key={social.name} href={social.url} rel="noopener noreferrer" target="_blank">
          {social.name}
          </a>
        ))}
      </SocialMedia>
    </Content>
  </Wrapper>
);

export default Header;

Header.propTypes = {
  avatar: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  infos: PropTypes.array.isRequired,
  socialMedia: PropTypes.array.isRequired,
};
