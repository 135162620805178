import React from 'react';
import styled from 'react-emotion';

const Content = styled('p')`
  color: ${props => props.theme.colors.secondary};
  padding: 0 ${props => props.theme.contentPadding};
  text-align: center;
`;

const Footer = () => (
  <Content>
    &copy; 2018 by Maarit Rajahalme. Pictures by{' '}
    <a href="mailto:maarit@rajahalme.art">
      Maarit Rajahalme
    </a>
    . Design based on a template by{' '}
    <a href="https://www.lekoarts.de/en" target="_blank" rel="noopener noreferrer">
      LekoArts
    </a>
    .
  </Content>
);

export default Footer;
